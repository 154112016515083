// src/Pages/Home.js
import React from "react";
import Form from "../components/Form";
import List from "../components/List";
import Map from "../components/Map";
import "../styles/main.css";

const Home = () => (
  <div className="home-container">
    <div className="left-column">
      <div className="form-container">
        <Form />
      </div>
      <div className="list-container">
        <List />
      </div>
    </div>
    <div className="right-column">
      <Map />
    </div>
  </div>
);

export default Home;
