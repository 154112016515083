// src/components/Map.js
import React, { useEffect, useRef, useContext } from "react";
import { MapLocationContext } from "../contexts/MapLocationContext";
import {
  loadGoogleMaps,
  initMap,
  addMarker,
  clearMarkers,
} from "../utils/mapUtils";

const Map = () => {
  const mapRef = useRef(null);
  const { locations } = useContext(MapLocationContext);

  useEffect(() => {
    const initializeMap = async () => {
      try {
        await loadGoogleMaps(process.env.REACT_APP_API_KEY);

        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            const center = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            initMap(mapRef, center);

            new window.google.maps.Marker({
              position: center,
              map,
              title: "You are here",
            });
          });
        }

        clearMarkers();
        locations.forEach((location) => addMarker(location));
      } catch (error) {
        console.error("Error initializing map: ", error);
      }
    };

    initializeMap();
  }, [locations]);

  return (
    <div ref={mapRef} id="map" style={{ height: "100%", width: "100%" }} />
  );
};

export default Map;
