// src/contexts/MapLocationContext.js
import React, { createContext, useState } from 'react';

export const MapLocationContext = createContext();

export const MapLocationProvider = ({ children }) => {
  const [locations, setLocations] = useState([]);

  const addLocation = (location) => {
    setLocations([...locations, location]);
  };

  return (
    <MapLocationContext.Provider value={{ locations, addLocation }}>
      {children}
    </MapLocationContext.Provider>
  );
};
