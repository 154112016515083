// src/components/List.js
import React, { useEffect, useState } from "react";
import { fetchCollectionDocs } from "../utils/firestoreUtils";

const List = () => {
  const [potties, setPotties] = useState([]);
  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchCollectionDocs("PottyCollection");
        setPotties(data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    const fetchUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setUserLocation({ latitude, longitude });
          },
          (error) => {
            console.error("Error fetching user location: ", error);
          }
        );
      }
    };

    fetchData();
    fetchUserLocation();
  }, []);

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const toRad = (value) => (value * Math.PI) / 180;
    const R = 6371; // Radius of the Earth in km
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  };

  const sortedPotties = userLocation
    ? potties.sort((a, b) => {
        const distanceA = calculateDistance(
          userLocation.latitude,
          userLocation.longitude,
          a.latitude,
          a.longitude
        );
        const distanceB = calculateDistance(
          userLocation.latitude,
          userLocation.longitude,
          b.latitude,
          b.longitude
        );
        return distanceA - distanceB;
      })
    : potties;

  return (
    <ul>
      {sortedPotties.map((potty) => (
        <li key={potty.id}>
          <h2>{potty.pottyName}</h2>
          <p>{potty.pottyAddress}</p>
          <p>{potty.pottyNotes}</p>
          <p>{potty.pottyRule}</p>
        </li>
      ))}
    </ul>
  );
};

export default List;
