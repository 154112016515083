// src/utils/mapUtils.js
let isGoogleMapsLoaded = false;
let map;
const markers = [];

export const loadGoogleMaps = (apiKey) => {
  if (isGoogleMapsLoaded) return Promise.resolve();

  return new Promise((resolve, reject) => {
    if (
      typeof window.google === "object" &&
      typeof window.google.maps === "object"
    ) {
      isGoogleMapsLoaded = true;
      resolve();
      return;
    }

    window.initMap = () => {
      isGoogleMapsLoaded = true;
      resolve();
    };

    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initMap&libraries=places`;
    script.async = true;
    script.defer = true;
    script.onerror = (error) => reject(error);

    document.head.appendChild(script);
  });
};

export const initMap = (mapRef, center) => {
  if (!map && mapRef.current) {
    map = new google.maps.Map(mapRef.current, {
      center,
      zoom: 8,
    });
  }
};

export const addMarker = (location) => {
  if (!map) return;

  const marker = new google.maps.Marker({
    position: { lat: location.latitude, lng: location.longitude },
    map,
    title: location.pottyName,
  });

  const infoWindow = new google.maps.InfoWindow({
    content: `
      <div>
        <h2>${location.pottyName}</h2>
        <p>${location.pottyAddress}</p>
        <p>${location.pottyNotes}</p>
        <p>${location.pottyRule}</p>
      </div>
    `,
  });

  marker.addListener("click", () => {
    infoWindow.open(map, marker);
  });

  map.addListener("click", () => {
    infoWindow.close();
  });

  markers.push(marker);
};

export const clearMarkers = () => {
  markers.forEach((marker) => marker.setMap(null));
  markers.length = 0;
};
