// src/components/Form.js
import React, { useState, useContext } from "react";
import { MapLocationContext } from "../contexts/MapLocationContext";
import { addDocument } from "../utils/firestoreUtils";

const Form = () => {
  const { locations, setLocations } = useContext(MapLocationContext);
  const [formData, setFormData] = useState({
    pottyName: "",
    pottyAddress: "",
    pottyNotes: "",
    pottyRule: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDocument("locations", formData);
      setLocations([...locations, formData]);
      setFormData({
        pottyName: "",
        pottyAddress: "",
        pottyNotes: "",
        pottyRule: "",
      });
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        name="pottyName"
        value={formData.pottyName}
        onChange={handleChange}
        placeholder="Name"
      />
      <input
        type="text"
        name="pottyAddress"
        value={formData.pottyAddress}
        onChange={handleChange}
        placeholder="Address"
      />
      <textarea
        name="pottyNotes"
        value={formData.pottyNotes}
        onChange={handleChange}
        placeholder="Notes"
      ></textarea>
      <input
        type="text"
        name="pottyRule"
        value={formData.pottyRule}
        onChange={handleChange}
        placeholder="Rule"
      />
      <button type="submit">Add Potty</button>
    </form>
  );
};

export default Form;
