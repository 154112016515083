// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import { MapLocationProvider } from "./contexts/MapLocationContext";

function App() {
  return (
    <MapLocationProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
        </div>
      </Router>
    </MapLocationProvider>
  );
}

export default App;
