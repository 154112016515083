// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Firebase configuration object
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "potty-snitch.firebaseapp.com",
  databaseURL: "https://potty-snitch-default-rtdb.firebaseio.com",
  projectId: "potty-snitch",
  storageBucket: "potty-snitch.appspot.com",
  messagingSenderId: "499783816793",
  appId: "1:499783816793:web:2b68d1fefac377c83f0348",
  measurementId: "G-5HTN19WPKZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db, firebaseConfig };
